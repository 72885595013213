import React from "react"
import Store from './src/components/store';

export const wrapPageElement = ({ element }) => {
  if (element.key.includes("quiz")) {
    return (
      <Store>{element}</Store>
    )
  }

  return element;
}
