import React, { useState } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const Store = ({ children }) => {
  const defaultQuizState = {
    currentQuestion: 1,
    answers: [],
  }
  const [quizState, setQuizState] = useState(defaultQuizState)

  const handleAnswer = answer => () => {
    const { currentQuestion, answers } = quizState

    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: answer.question.text,
      // string - required - Type of interaction (e.g. 'play')
      action: `${answer.question.number}`,
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: `${answer.answer.number}`,
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value: answer.answer.number,
    })

    setQuizState({
      currentQuestion: currentQuestion + 1,
      answers: [...answers, answer],
    })
  }

  // NOTE: we consider here, that children is a single element, not an array
  // because the template src/templates/quiz.js returns the only one child
  const element = React.cloneElement(children, {
    quizState,
    handleAnswer,
  })

  return element
}

export default Store
